// Don't edit manually. `rake js:routes` generates this file.
type Value = string | number
type Params<Keys extends string> = { [key in Keys]: Value } & Record<string, Value>
function process(route: string, params: Record<string, Value> | undefined, keys: string[]): string {
  if (!params) return route
  var query: string[] = [];
  for (var param in params) if (params.hasOwnProperty(param)) {
    if (keys.indexOf(param) === -1) {
      query.push(param + "=" + encodeURIComponent(params[param].toString()));
    }
  }
  return query.length ? route + "?" + query.join("&") : route;
}

export function finishConfirmQuestionSubjectQuestionSessionPath(params: Params<'questionSubjectId' | 'id'>) { return process('/question_subjects/' + params.questionSubjectId + '/question_sessions/' + params.id + '/finish_confirm', params, ['questionSubjectId','id']); }
export function abortQuestionSubjectQuestionSessionPath(params: Params<'questionSubjectId' | 'id'>) { return process('/question_subjects/' + params.questionSubjectId + '/question_sessions/' + params.id + '/abort', params, ['questionSubjectId','id']); }
export function testGateQuestionSubjectQuestionSessionPath(params: Params<'questionSubjectId' | 'id'>) { return process('/question_subjects/' + params.questionSubjectId + '/question_sessions/' + params.id + '/test_gate', params, ['questionSubjectId','id']); }
export function countPracticeQuestionsQuestionSubjectQuestionSessionsPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/question_sessions/count_practice_questions', params, ['questionSubjectId']); }
export function editQuestionSubjectQuestionSessionProgressingQuestionPath(params: Params<'questionSubjectId' | 'questionSessionId' | 'id'>) { return process('/question_subjects/' + params.questionSubjectId + '/question_sessions/' + params.questionSessionId + '/progressing_questions/' + params.id + '/edit', params, ['questionSubjectId','questionSessionId','id']); }
export function questionSubjectQuestionSessionProgressingQuestionPath(params: Params<'questionSubjectId' | 'questionSessionId' | 'id'>) { return process('/question_subjects/' + params.questionSubjectId + '/question_sessions/' + params.questionSessionId + '/progressing_questions/' + params.id + '', params, ['questionSubjectId','questionSessionId','id']); }
export function questionSubjectQuestionSessionResultPath(params: Params<'questionSubjectId' | 'questionSessionId'>) { return process('/question_subjects/' + params.questionSubjectId + '/question_sessions/' + params.questionSessionId + '/result', params, ['questionSubjectId','questionSessionId']); }
export function questionSubjectQuestionSessionsPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/question_sessions', params, ['questionSubjectId']); }
export function questionSubjectQuestionSessionPath(params: Params<'questionSubjectId' | 'id'>) { return process('/question_subjects/' + params.questionSubjectId + '/question_sessions/' + params.id + '', params, ['questionSubjectId','id']); }
export function searchQuestionSubjectQuestionsPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/questions/search', params, ['questionSubjectId']); }
export function questionSubjectQuestionNotePath(params: Params<'questionSubjectId' | 'questionId'>) { return process('/question_subjects/' + params.questionSubjectId + '/questions/' + params.questionId + '/note', params, ['questionSubjectId','questionId']); }
export function questionSubjectQuestionsPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/questions', params, ['questionSubjectId']); }
export function questionSubjectQuestionPath(params: Params<'questionSubjectId' | 'id'>) { return process('/question_subjects/' + params.questionSubjectId + '/questions/' + params.id + '', params, ['questionSubjectId','id']); }
export function questionSubjectSummaryPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/summary', params, ['questionSubjectId']); }
export function questionSubjectNotesPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/notes', params, ['questionSubjectId']); }
export function questionSubjectFavoritesPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/favorites', params, ['questionSubjectId']); }
export function questionSubjectFavoriteGroupPath(params: Params<'questionSubjectId' | 'id'>) { return process('/question_subjects/' + params.questionSubjectId + '/favorite_groups/' + params.id + '', params, ['questionSubjectId','id']); }
export function questionSubjectLearningHistoryPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/learning_history', params, ['questionSubjectId']); }
export function questionSubjectEveryonePath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/everyone', params, ['questionSubjectId']); }
export function questionSubjectCorporationEveryonePath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/corporation_everyone', params, ['questionSubjectId']); }
export function editQuestionSubjectSettingPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/setting/edit', params, ['questionSubjectId']); }
export function questionSubjectSettingPath(params: Params<'questionSubjectId'>) { return process('/question_subjects/' + params.questionSubjectId + '/setting', params, ['questionSubjectId']); }
export function questionSubjectPath(params: Params<'id'>) { return process('/question_subjects/' + params.id + '', params, ['id']); }
export function markdownPreviewPath(params?: Record<string, Value>) { return process('/markdown_preview', params, []); }
export function corporationStaffsCorporationUserAllLearningHistoryPath(params: Params<'corporationUserId'>) { return process('/corporation_staffs/corporation_users/' + params.corporationUserId + '/all_learning_history', params, ['corporationUserId']); }
export function corporationStaffsCorporationUserQuestionSubjectQuestionSessionsPath(params: Params<'corporationUserId' | 'questionSubjectId'>) { return process('/corporation_staffs/corporation_users/' + params.corporationUserId + '/question_subjects/' + params.questionSubjectId + '/question_sessions', params, ['corporationUserId','questionSubjectId']); }
export function editCorporationStaffsCorporationUserUserPasswordPath(params: Params<'corporationUserId'>) { return process('/corporation_staffs/corporation_users/' + params.corporationUserId + '/user/password/edit', params, ['corporationUserId']); }
export function corporationStaffsCorporationUserUserPasswordPath(params: Params<'corporationUserId'>) { return process('/corporation_staffs/corporation_users/' + params.corporationUserId + '/user/password', params, ['corporationUserId']); }
export function corporationStaffsCorporationUsersPath(params?: Record<string, Value>) { return process('/corporation_staffs/corporation_users', params, []); }
export function editCorporationStaffsCorporationUserPath(params: Params<'id'>) { return process('/corporation_staffs/corporation_users/' + params.id + '/edit', params, ['id']); }
export function corporationStaffsCorporationUserPath(params: Params<'id'>) { return process('/corporation_staffs/corporation_users/' + params.id + '', params, ['id']); }
export function corporationStaffsQuestionSubjectQuestionBranchesPath(params: Params<'questionSubjectId'>) { return process('/corporation_staffs/question_subjects/' + params.questionSubjectId + '/question_branches', params, ['questionSubjectId']); }
export function confirmCorporationStaffsLicenseOrderFormPath(params?: Record<string, Value>) { return process('/corporation_staffs/license_order_form/confirm', params, []); }
export function checkCouponCodeCorporationStaffsLicenseOrderFormPath(params?: Record<string, Value>) { return process('/corporation_staffs/license_order_form/check_coupon_code', params, []); }
export function newCorporationStaffsLicenseOrderFormPath(params?: Record<string, Value>) { return process('/corporation_staffs/license_order_form/new', params, []); }
export function corporationStaffsLicenseOrderFormPath(params?: Record<string, Value>) { return process('/corporation_staffs/license_order_form', params, []); }
export function apiV1FavoriteGroupFavoritesPath(params: Params<'favoriteGroupId'>) { return process('/api/v1/favorite_groups/' + params.favoriteGroupId + '/favorites', params, ['favoriteGroupId']); }
export function apiV1FavoriteGroupFavoritePath(params: Params<'favoriteGroupId' | 'id'>) { return process('/api/v1/favorite_groups/' + params.favoriteGroupId + '/favorites/' + params.id + '', params, ['favoriteGroupId','id']); }
export function apiV1LargeCategoryMiddleCategoryQuestionSubjectsPath(params: Params<'largeCategoryId' | 'middleCategoryId'>) { return process('/api/v1/large_categories/' + params.largeCategoryId + '/middle_categories/' + params.middleCategoryId + '/question_subjects', params, ['largeCategoryId','middleCategoryId']); }
export function apiV1LargeCategoryMiddleCategoriesPath(params: Params<'largeCategoryId'>) { return process('/api/v1/large_categories/' + params.largeCategoryId + '/middle_categories', params, ['largeCategoryId']); }
export function apiV1NotificationReadsPath(params?: Record<string, Value>) { return process('/api/v1/notification_reads', params, []); }
export function apiV1CapturesPath(params?: Record<string, Value>) { return process('/api/v1/captures', params, []); }
export function globalPostPostReplyPostReplyCommentsPath(params: Params<'postId' | 'postReplyId'>) { return process('/g/posts/' + params.postId + '/post_replies/' + params.postReplyId + '/post_reply_comments', params, ['postId','postReplyId']); }
export function globalPostPostRepliesPath(params: Params<'postId'>) { return process('/g/posts/' + params.postId + '/post_replies', params, ['postId']); }
export function globalPostPostCommentsPath(params: Params<'postId'>) { return process('/g/posts/' + params.postId + '/post_comments', params, ['postId']); }
export function globalPostsPath(params?: Record<string, Value>) { return process('/g/posts', params, []); }
export function newGlobalPostPath(params?: Record<string, Value>) { return process('/g/posts/new', params, []); }
export function globalPostPath(params: Params<'id'>) { return process('/g/posts/' + params.id + '', params, ['id']); }
export function globalCorporationLicenseQuotationPath(params?: Record<string, Value>) { return process('/g/corporation_license_quotation', params, []); }
