import html2canvas from 'html2canvas';
import client from '@/lib/api/client';
import { apiV1CapturesPath } from '@/lib/rails-routes';
import toastr from 'toastr/toastr';

const adjustIframe = (iframe: HTMLIFrameElement, additionalHeight: number = 0):void => {
  const { contentWindow } = iframe;
  if (!contentWindow) return;

  const height = contentWindow.document.body.scrollHeight;
  iframe.style.height = `${height + additionalHeight}px`;
};

const adjustIframeOnLoad = (iframe: HTMLIFrameElement, additionalHeight: number = 0):void => {
  iframe.addEventListener('load', () => adjustIframe(iframe, additionalHeight));
};

const questionSubjectFormSessionStorageKey = (questionSubjectId: number, formType: 'practice' | 'test') => `question-subject-form-${formType}_${questionSubjectId}`;

const reachedYear = (month: number): boolean => month >= 12;

const monthToDays = (month: number) => (reachedYear(month) ? (month / 12) * 365 : month * 30);

const monthToTerm = (month: number) => (reachedYear(month) ? `${month / 12}年(${monthToDays(month)}日)` : `${month}ヶ月(${monthToDays(month)}日)`);

const numberToYen = (number: number) => `${number.toLocaleString()}円`;

const postX = (target: HTMLElement, xPostText: string) => {
  try {
    html2canvas(target).then((canvas) => {
      canvas.toBlob(async (blob) => {
        const params = new FormData();
        params.append('file', blob);
        const response = await client.post(apiV1CapturesPath(), params);
        if (response.status === 200) {
          window.open(`https://x.com/intent/tweet?url=${response.data.url}&text=${xPostText}`, '_blank');
        } else {
          toastr.error('POSTに失敗しました');
        }
      });
    });
  } catch {
    toastr.error('POSTに失敗しました');
  }
};

export {
  adjustIframe,
  adjustIframeOnLoad,
  questionSubjectFormSessionStorageKey,
  monthToDays,
  monthToTerm,
  numberToYen,
  postX,
};
