import axios from 'axios';
import Rails from '@rails/ujs';

const client = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': Rails.csrfToken(),
  },
});

export default client;
